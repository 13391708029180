import React from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import "../index.css";
import { Link } from "react-router-dom";
import List from './tenent/List'

function Invoice(){
	return(
		<>
			<Header />

			<List />

			
			
		</>
	)
}

export default Invoice;