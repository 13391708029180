import React,{useState,useEffect} from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import {useLocation,Link } from 'react-router-dom';
import logo from '../../images/logo.png';
const ThankYou = () => {
	let { state } = useLocation();
	return (

			<>

			<HStack bg="#fff" h="100vh" justifyContent="center" flexDirection="column">

      

		      <Box width="481px" bg="#fff" py="30" px="80px" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="5px" boxShadow="2px 4px 4px 0px #00000040">
		        <Image m="auto" src={logo} alt='Woosh Stories' />
		        <Text fontSize="16px" fontWeight="400" fontFamily="'Montserrat', sans-serif" textAlign="center" color="#000" my="20px">Thank You</Text>
		      
		           
		        	
					

		         

		           <Link className="forget_link" to={`https://${state.domain}.wooshstories.com`}>Login here</Link>
		      
		        

		        
		      </Box>

		      <Box mt="20px">
		        <Text fontSize="16px" fontWeight="400" color="#000" fontFamily="'Lato', sans-serif">Copyright 2023, e.ocean</Text>
		      </Box>
		    </HStack>
			
			</>
		)
}

export default ThankYou