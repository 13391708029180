import React,{useEffect,useState} from 'react';
import CustomModal from '../../../components/CustomModal'
import CustomModalLink from '../../../components/CustomModalLink'
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
  useDisclosure,
  Spinner
} from '@chakra-ui/react'
import Create from './Create';
import Edit from './Edit';
import { fetchWrapper } from '../../../helpers';
import Pagination from "react-js-pagination";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { BsPencil, BsFillTrashFill, BsFillCaretDownFill } from "react-icons/bs";
import { toast } from "react-toastify";

function List(){
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [loading,setLoading] = useState(false)
	const { onOpen:onOpenEdit, onClose:onCloseEdit } = useDisclosure();
	const [isOpenEdit,setIsOpenEdit] = useState(false)   
	const [modalEditView,setModalEditView] = useState(false)
	const [listCount, setListCount] = useState(0); 
	const [activePage, setActivePage] = useState(1);

  const [list,setList] = useState([])
  useEffect(() => {
        loadList()
    }, [activePage]);

  const loadList = async () => {
  		setLoading(true)
      const url = `${process.env.REACT_APP_API_URL}/language/list?page=${activePage}`;
      const data  = await fetchWrapper.get(url)
      setList(data.data.rows);
      setListCount(data.data.count);
      setLoading(false)
  }

  const meClose = () => {
  		
		   onClose();
		   
	}

	const meCloseEdit = () => {
  		
		   setIsOpenEdit(false);
		   
	}

	const meEdit = (rs) => {
		setIsOpenEdit(true);
		setModalEditView(<Edit rs={rs} meCloseEdit={meCloseEdit}  loadList={loadList} />)		
	}

	const meDelete = async (rs) => {
			const aa = window.confirm("Are you sure delete this recored");
			if(aa){
					setLoading(true)
					const url = `${process.env.REACT_APP_API_URL}/language/list/${rs.id}`;
	  		 await fetchWrapper.delete(url)
	  		 loadList()
	  		 setLoading(false)
	  		 toast(`Record has been deleted `, {
	        type: "info"
	      });
			}
	}

	const onChangePaginate = (pageNumber) => {
    setActivePage(pageNumber);
    // to set offset
    console.log(pageNumber);
  };

  return(
    <>
    	<CustomModalLink
          showModalButtonText="Edit"
          modalHeader="Edit"
       		isOpenEdit={isOpenEdit}
       		onCloseEdit={onCloseEdit}
       		onOpenEdit={onOpenEdit}
          
          modalBody={modalEditView}
    />
    
      <div className="setting_page_section">
     		{loading &&
	      	<HStack width="100%" h="100%" top="0" left="0" bg="rgba(204, 204, 204, 0.50)" position="absolute" zIndex="9999" justifyContent="center">
			    	<Spinner
						  thickness='4px'
						  speed='0.65s'
						  emptyColor='gray.200'
						  color='blue.500'
						  size='xl'
						/>
					</HStack>
				}
				<Box px="20px" py="10px" backgroundColor="#fff" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="10px">     	
					<HStack justifyContent="space-between" mb="25px" alignItems="center">
						<Box>
							<Text fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" color="#000">Story Language</Text>
						</Box>

						<Box>
							<CustomModal
								
	              showModalButtonText="Add New Story Language"
	              modalHeader="Create Story Language"
	              isOpen={isOpen}
	           		onClose={onClose}
	           		onOpen={onOpen}

	              modalBody=<Create meClose={meClose} loadList={loadList} />
	            />
						</Box>		
					</HStack>

					<div className="table_section">
						<TableContainer width="100%" borderWidth="0px !important">
						  <Table variant='simple'>
						    <Thead>
						      <Tr>
						        <Th>Name</Th>
						        <Th>Language Name</Th>
						        <Th></Th>
						      </Tr>
						    </Thead>
						    <Tbody>
						    {list?.map((rs,index) => {

						    		return(



						    			<Tr key={index}>
								        <Td>{rs.name}</Td>
								        <Td>{rs.lang_name}</Td>
								        <Td>
								        	<Menu>
													  <MenuButton>
													  	<HStack>
													  		<Text color="#0A83FF">Actions</Text>

													  		<BsFillCaretDownFill color="#0A83FF" /> 
													  	</HStack>
													    
													  </MenuButton>
													  <MenuList>
													    <MenuItem><a onClick={() => meEdit(rs)}>Edit</a></MenuItem>
													    <MenuItem><a onClick={() => meDelete(rs)}>Delete</a></MenuItem>
													  </MenuList>
													</Menu>
								        </Td>
								      </Tr>

						    		)


						    })}
						      
						      
						    </Tbody>
						    
						  </Table> 

						</TableContainer>
						<Pagination
							 activePage={activePage}
							 itemsCountPerPage={10}
							 totalItemsCount={listCount}
							 pageRangeDisplayed={5}
							 onChange={onChangePaginate}
							 innerClass="pagination"
							 itemClass="page-item"
							 activeLinkClass="page-link active"
							 linkClass="page-link"
							 prevPageText="Previous"
							 nextPageText="Next"
							/>
					</div>
				</Box>

				
			</div>

      
		</>
    )
}

export default List;