import React from 'react';
import Header from '../components/Header';
import { HStack, Box, Image, Link, Text } from '@chakra-ui/react';
import "../index.css";

function Dashboard(){
	return(
		<>
			<Header />

			<div className="body_section">			
				<HStack>
					<Text>Dashboard</Text>
				</HStack>
			</div>
		</>
	)
}

export default Dashboard;