import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../components/form'
import { HStack, Box } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';


function Create({loadList,meClose}){

	

	const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email:'',
    role:'',
    domain:'',
    
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   company: Yup.string().required('Required'),
	   
	   phone: Yup.string().required('Required'),
	   email: Yup.string().required('Required'),
	   role: Yup.string().required('Required'),
	   domain: Yup.string().required('Required'),


	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/tenent/create`;
  		 await fetchWrapper.post(url,data)
  		 meClose();
  		 loadList()
  		  toast(`Record has been saved `, {
	        type: "info"
	      });
  		 
	}

	


	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			

					<Box w="100%">
			 			<InputText name="name" title="Name"  />
			 		</Box>



			 		<Box w="100%">
			 			<InputText name="domain" title="Domain"  />
			 		</Box>

			 		<Box w="100%">
			 			<InputText name="company" title="Company"  />
			 		</Box>

			 		<Box w="100%">
			 			<InputText name="phone" title="Phone"  />
			 		</Box>


			 		<Box w="100%">
			 			<InputText name="email" title="Email Address"  />
			 		</Box>

			 		<Box w="100%">
			 			<InputText name="role" title="Role"  />
			 		</Box>


		         <Button py="10px" px="25px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

			</Form>

			</>

	)
}

export default Create;