import React from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import Headerlogo from '../images/logo.svg';
import { Link,useNavigate } from "react-router-dom";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { BsFillCaretDownFill } from "react-icons/bs";
import "../index.css";

function Header(){
	const navigate = useNavigate();
 const logout = () => {
      
        localStorage.removeItem('user');
        navigate('/login');
  }

	return(
		<HStack h="70px" bg="#0A83FF" py="10px" px="50px" justifyContent="space-between">
			<Box>
				<HStack>
					<Box>
						<Image src={Headerlogo} alt='Gun Guru' />
					</Box>

					<Box ml="60px">
						<HStack>
							<Box me="40px">
								<Link className="header_link" to="/tenent">Tenent</Link>
							</Box>

							<Box me="40px">
								<Link className="header_link" to="/invoice">Invoices</Link>
							</Box>


							<Box me="40px">
								<Link className="header_link" to="/plan">Plans</Link>
							</Box>

							<Box me="40px">
								<Link className="header_link" to="/settings">Settings</Link>
							</Box>

							
						</HStack>
					</Box>
				</HStack>
			</Box>

			<Box>
				<Menu>
				  <MenuButton>
				    <HStack>
				    	<Box>
				    		<Box width="40px" height="40px" borderWidth="1px" borderStyle="solid" borderRadius="50px" display="flex" justifyContent="center" alignItems="center">
				    			<Text fontSize="16px" fontWeight="700" fontFamily="'Montserrat', sans-serif" color="#fff">D</Text>
				    		</Box>
				    	</Box>

				    	<Box>
				    		<Text fontSize="12px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#fff" textAlign="left">Welcome</Text>
				    		<HStack><Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#fff" textAlign="left" display="flex" alignItems="center">Dilawar Abbas</Text> <BsFillCaretDownFill color="#fff" fontSize="10px" /></HStack>
				    	</Box>
				    </HStack>
				  </MenuButton>
				  <MenuList>
				    <MenuItem minH='48px'>
				      <Link onClick={() => logout()}>Logout</Link>
				    </MenuItem>
				  </MenuList>
				</Menu>
			</Box>
		</HStack>
	);
}

export default Header;