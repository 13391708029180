import React,{useState} from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import { Link } from "react-router-dom";
import stripe_img from '../../images/setting_img/stripe.png'
import stripe_img_active from '../../images/setting_img/stripe_active.png'
import email_img from '../../images/setting_img/email.png'
import email_img_active from '../../images/setting_img/email_active.png'
import notification_img from '../../images/setting_img/notification.png'
import notification_img_active from '../../images/setting_img/notification_active.png'
import story_language_img_active from '../../images/setting_img/story_language_active.png'
import user_img from '../../images/setting_img/user.png'
import industry_img from '../../images/setting_img/industry.png'

function LeftNav({loadPage}){
	const [activeLink, setActiveLink] = useState('stripe');

	const handleClick = (linkName) => {
    setActiveLink(linkName);
    loadPage(linkName);
};
	return(
		<>
			<Box backgroundColor="#fff" borderWidth="1px" borderStyle="solid" borderColor="#ccc" py="10px" borderRadius="10px">
				<UnorderedList m="0px" p="0px" listStyleType="none">
				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'stripe' ? 'active' : ''}`} onClick={() => handleClick('stripe')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={stripe_img} />
				  				<Image className="icon_active_img" src={stripe_img_active} />
				  			</Box>
				  			<Text>Stripe</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>

				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'email' ? 'active' : ''}`} onClick={() => handleClick('email')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={email_img} />
				  				<Image className="icon_active_img" src={email_img_active} />
				  			</Box>
				  			<Text>E-Mail</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>
				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'notificationtemplate' ? 'active' : ''}`} onClick={() => handleClick('notificationtemplate')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={notification_img} />
				  				<Image className="icon_active_img" src={notification_img_active} />
				  			</Box>
				  			<Text>Notification Templates</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>
				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'storylanguage' ? 'active' : ''}`} onClick={() => handleClick('storylanguage')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={story_language_img_active} />
				  				<Image className="icon_active_img" src={story_language_img_active} />
				  			</Box>
				  			<Text>Story Language</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>
				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'authortype' ? 'active' : ''}`} onClick={() => handleClick('authortype')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={user_img} />
				  				<Image className="icon_active_img" src={user_img} />
				  			</Box>
				  			<Text>Author</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>
				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'industry' ? 'active' : ''}`} onClick={() => handleClick('industry')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={industry_img} />
				  				<Image className="icon_active_img" src={industry_img} />
				  			</Box>
				  			<Text>Industry</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>
				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'user' ? 'active' : ''}`} onClick={() => handleClick('user')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={user_img} />
				  				<Image className="icon_active_img" src={user_img} />
				  			</Box>
				  			<Text>Users</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>

				  <ListItem>
				  	<Link className={`setting_nav_link ${activeLink === 'country' ? 'active' : ''}`} onClick={() => handleClick('country')}>
				  		<HStack>
				  			<Box width="30px" height="30px" display="flex" alignItems="center" justifyContent="center">
				  				<Image className="icon_img" src={user_img} />
				  				<Image className="icon_active_img" src={user_img} />
				  			</Box>
				  			<Text>Country</Text>
				  		</HStack>
				  	</Link>
				  </ListItem>


				</UnorderedList>
			</Box>
		</>
	);
}

export default LeftNav;