import React,{useState} from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import * as Yup from 'yup';
import { fetchWrapper } from '../../helpers';
import { Link, useNavigate } from "react-router-dom";
import {
  
  Button
} from '@chakra-ui/react';
import logo from '../../images/logo.png';
import {InputText,Form} from '../../components/form'

function Login() {

  const navigate  = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    
    
  });

  const FormSchema = Yup.object().shape({
       username: Yup.string()
            .required('Required'),
      password: Yup.string()
            .required('Required'),
    
       
  });

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/auth/signin`;
       const loginData = await fetchWrapper.post(url,data)

      
       localStorage.setItem("user", loginData.accessToken);
        navigate("/dashboard");
  }


  return (
    <HStack bg="#fff" h="100vh" justifyContent="center" flexDirection="column">

      

      <Box width="481px" bg="#fff" py="30" px="80px" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="5px" boxShadow="2px 4px 4px 0px #00000040">
        <Image m="auto" src={logo} alt='Woosh Stories' />
        <Text fontSize="16px" fontWeight="400" fontFamily="'Montserrat', sans-serif" textAlign="center" color="#000" my="20px">Please login into your account</Text>
      <Form
                  enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit={onSubmit}
       >
           <InputText name="username" title="User Name"  />
           <InputText inputProps={{type:'password'}}  name="password" title="Password"  />


           <Button type="submit" bg="#0A83FF" borderColor="#0A83FF" borderRadius="5px" fontSize="14px" fontWeight="500" color="#fff" width="100%" p="22px" borderWidth="1px" borderStyle="solid">Login</Button>
            <br /><br />
           <Button type="button"

            onClick={() => navigate('/register')}
            bg="#328cdd" borderColor="#328cdd" borderRadius="5px" fontSize="14px" fontWeight="500" color="#fff" width="100%" p="22px" borderWidth="1px" borderStyle="solid">Register</Button>

           <Link className="forget_link">Forgot Password?</Link>
       </Form>
        

        
      </Box>

      <Box mt="20px">
        <Text fontSize="16px" fontWeight="400" color="#000" fontFamily="'Lato', sans-serif">Copyright 2023, e.ocean</Text>
      </Box>
    </HStack>
  );
}

export default Login;
