import React,{useState,useEffect} from 'react';
import { HStack, Box, Image, Text } from '@chakra-ui/react';
import * as Yup from 'yup';
import { fetchWrapper } from '../../helpers';
import { Link, useNavigate } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import {
  
  Button
} from '@chakra-ui/react';
import logo from '../../images/logo.png';
import {InputText,Form,SelectField} from '../../components/form'

const Register = () => {
const navigate  = useNavigate();

  const [countryList,setCountryList] = useState([])
  const [industryList,setIndustryList] = useState([])
  const [planId,setPlanId] = useState(0)
  const [planList,setPlanList] = useState([])
  useEffect(() => {
        loadCountryList()
        loadIndustryList()
        loadPlanList()
    }, []);

  const loadCountryList = async () => {
        
      const url = `${process.env.REACT_APP_API_URL}/country/list`;
      const data  = await fetchWrapper.get(url)
      setCountryList(data.data);
     
      
  }

  const loadPlanList = async () => {
        
      const url = `${process.env.REACT_APP_API_URL}/plan/list`;
      const data  = await fetchWrapper.get(url)
      setPlanList(data.data);
     
      
  }

  const loadIndustryList = async () => {
        
      const url = `${process.env.REACT_APP_API_URL}/industry/list`;
      const data  = await fetchWrapper.get(url)
      setIndustryList(data.data);
     
      
  }


  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    role: '',
    industry_id:'',
    country_id:'',
    username:'',
    password:'',
    domain:'',
    confirmPassword:'',
    
    
  });

  const FormSchema = Yup.object().shape({
       email: Yup.string()
            .required('Required'),
      password: Yup.string()
            .required('Required'),


     domain: Yup.string()
            .required('Required'),

    
       
  });
  const selectPlan = (plan_id) => {

    setPlanId(plan_id)
  }

  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
      // navigate("/thank-you",{state:{ domain: 'sanir' }});
       let  data = values
       
       if(planId == 0){
            alert("Please, select plan");
            return false;
       }

       data.plan_id = planId;
         
       const url = `${process.env.REACT_APP_API_URL}/tenent/create-tenent`;
       const loginData = await fetchWrapper.post(url,data).then(dataRs => {

            navigate("/thank-you",{state:{ domain: data.domain }});

       }).catch(err => {

            
            alert(err)
       })

      
       
        
  }

	return (
			<HStack bg="#fff" justifyContent="left" flexDirection="column" px="50px">
			<Box width="100%" bg="#fff" py="30">
        <Image mb="40px" src={logo} alt='Woosh Stories' />
        <Text mb="40px" fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000">Sign up for an Account</Text>

        <Form
          enableReinitialize
          validationSchema={FormSchema}
          initialValues={formData}
          onSubmit={onSubmit}
       >
            <HStack alignItems="top" justifyContent="space-between">
                <Box width="50%">
                    <Box mb="50px">
                        <Text mb="20px" fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">Personal Details</Text>

                        <HStack>
                            <Box width="50%" me="30px">
                                <InputText name="name" title="Full Name"  />
                            </Box>

                            <Box width="50%">

                            </Box>
                        </HStack>

                        <HStack>
                            <Box width="50%" me="30px">
                                <InputText name="phone" title="Contact No"  />
                            </Box>

                            <Box width="50%">
                                <InputText name="email" title="E-Mail Address"  />
                            </Box>
                        </HStack>

                        <HStack>
                            <Box width="50%" me="30px">
                                <InputText name="company" title="Company Name"  />
                            </Box>

                            <Box width="50%">
                                <InputText name="role" title="Role"  />
                            </Box>
                        </HStack>

                        <HStack>
                            <Box width="50%" me="30px">
                                
                                <SelectField list={industryList} name="industry_id" title="Industry"  />
                            </Box>

                            <Box width="50%">
                                <SelectField list={countryList} name="country_id" title="Country"  />
                                
                            </Box>
                        </HStack>
                    </Box>

                    <Box mb="50px">
                        <Text mb="20px" fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">Account Details</Text>

                        <HStack>
                            <Box width="50%" me="30px">
                                <Text mb="5px" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">URL</Text>
                                <HStack alignItems="top">
                                    <Box width="50%">
                                        <InputText name="domain" title=""  />
                                    </Box>

                                    <Box width="50%">
                                        <Text mt="11px" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">.wooshstories.com</Text>
                                    </Box>
                                </HStack>
                                
                            </Box>

                            <Box width="50%">

                            </Box>
                        </HStack>

                        <HStack>
                            <Box width="50%" me="30px">
                                <InputText inputProps={{type:'password'}} name="password" title="Password" />
                            </Box>

                            <Box width="50%">
                                <InputText inputProps={{type:'password'}} name="confirmPassword" title="Confirm Password" />
                            </Box>
                        </HStack>

                        <Button type="submit" bg="#0A83FF" borderColor="#0A83FF" borderRadius="5px" fontSize="14px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#fff" p="22px" borderWidth="1px" borderStyle="solid">Create My Account</Button>
                    </Box>
                </Box>

                <Box width="40%">
                    <HStack>
                        <Box width="100%">                           
                            <Tabs variant='soft-rounded'>
                              <TabList className="register_tabs_section" alignItems="center" justifyContent="space-between" mb="15px">
                                <Text fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">Plans</Text>
                               
                              </TabList>
                              <TabPanels>
                                <TabPanel p="0px">
                                 {planList?.rows?.map(rs => {
                                        if(rs.status_id == 1){
                                        return (



                                            <HStack onClick={() => selectPlan(rs.id)} mb="15px" justifyContent="space-between" width="100%" borderWidth="1px" borderColor={planId == rs.id ? "blue" : "black"} borderStyle="solid" borderRadius="10px" py="10px" px="30px">
                                    <Box  >
                                        <Text fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">{rs.name}</Text>
                                        <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">{rs.allow_story} Stories</Text>
                                        <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">Validity: {rs.subscription_days} Days</Text>
                                    </Box>

                                    <Box>
                                        <Text fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">$ {rs.monthly_cost} / month</Text>
                                    </Box>
                                  </HStack>


                                            )

                                        }
                                 })}
                                  

                                  
                                </TabPanel>
                                <TabPanel p="0px">

                                {planList?.rows?.map(rs => {

                                        return (



                                            <HStack mb="15px" justifyContent="space-between" width="100%" borderWidth="1px" borderStyle="solid" borderRadius="10px" py="10px" px="30px">
                                    <Box>
                                        <Text fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">{rs.name}</Text>
                                        <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">{rs.allow_story} Stories</Text>
                                        <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" color="#000">Validity: {rs.subscription_days} Days</Text>
                                    </Box>

                                    <Box>
                                        <Text fontSize="24px" fontWeight="500" fontFamily="'Montserrat', sans-serif" color="#000">$ {Math.round(rs.monthly_cost * 12,2)} / year</Text>
                                    </Box>
                                  </HStack>


                                            )
                                 })}
                                  
                                 
                                </TabPanel>
                              </TabPanels>
                            </Tabs>
                        </Box>
                    </HStack>
                </Box>
            </HStack>
       </Form>
      </Box>

      <Box mt="20px">
        <Text fontSize="16px" fontWeight="400" color="#000" fontFamily="'Lato', sans-serif">Copyright 2023, e.ocean</Text>
      </Box>

			</HStack>
		)
}

export default Register;