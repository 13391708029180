import React,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
} from '@chakra-ui/react'
import "../../index.css";
import {InputText,Form,SelectField,TextAreaField} from '../../components/form';
import { fetchWrapper } from '../../helpers';
import { toast } from "react-toastify";

function NotificationTemplate(){

  const [listData,setListData] = useState({})
  const [formData, setFormData] = useState({});
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
       
       
        
    }, []);


   const loadListData = async () => {
      const url = `${backendUrl}/settings/list`;
      const data  = await fetchWrapper.get(url)
      
      setFormData(
              {
                  welcome: findData('welcome',data.data),
                  trial_expired:findData('trial_expired',data.data),
                  subscription_purchase:findData('subscription_purchase',data.data),
                  subscription_expire:findData('subscription_expire',data.data),
                  payment_failed:findData('payment_failed',data.data),
                  grace_period_expired:findData('grace_period_expired',data.data),
               
                }
        )
  }

  const findData = (key,dataValue) => {
      
      const data =  dataValue?.filter(rs => rs.key == key);
      
      if(data){
          return data[0]?.value;
      }
      return '';
  }


  const FormSchema = Yup.object().shape({
     welcome: Yup.string().required('Required'),
     trial_expired: Yup.string().required('Required'),
     subscription_purchase: Yup.string().required('Required'),
     subscription_expire: Yup.string().required('Required'),
     payment_failed: Yup.string().required('Required'),
     grace_period_expired: Yup.string().required('Required'),
  });


  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/settings/create`;
       await fetchWrapper.post(url,data)
       toast(`Record has been saved `, {
          type: "info"
        });
      
       
  }

	return(
		<>
			<Box px="20px" py="10px" backgroundColor="#fff" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="10px">
        <Text fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" mb="20px" color="#000">Notification Templates</Text>

        <Form
        enableReinitialize
        validationSchema={FormSchema}
        initialValues={formData}
        onSubmit={onSubmit}>
          <HStack justifyContent="space-between">
            <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#000">Welcome Email</Text>

            <Link fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#0A83FF">View Email</Link>
          </HStack>

          <TextAreaField name="welcome" />

          <HStack justifyContent="space-between">
            <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#000">Trial Expired</Text>

            <Link fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#0A83FF">View Email</Link>
          </HStack>

          <TextAreaField name="trial_expired" />

          <HStack justifyContent="space-between">
            <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#000">Subscription Purchase Confirmation</Text>

            <Link fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#0A83FF">View Email</Link>
          </HStack>

          <TextAreaField name="subscription_purchase" />

          <HStack justifyContent="space-between">
            <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#000">Subscription Expiring (Auto Renewal)</Text>

            <Link fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#0A83FF">View Email</Link>
          </HStack>

          <TextAreaField name="subscription_expire" />

          <HStack justifyContent="space-between">
            <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#000">Payment Failed</Text>

            <Link fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#0A83FF">View Email</Link>
          </HStack>

          <TextAreaField name="payment_failed" />

          <HStack justifyContent="space-between">
            <Text fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#000">Grace Period Expired (Account Suspended)</Text>

            <Link fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" mb="5px" color="#0A83FF">View Email</Link>
          </HStack>

          <TextAreaField name="grace_period_expired" />

          
          <Button my="20px" h="unset" py="13px" px="40px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
            mb={4}
            
            type='submit'
          >
           Save
          </Button>
        </Form>
      </Box>
		</>
	)
}

export default NotificationTemplate;