import React,{useEffect,useState} from 'react';
import { Button,useDisclosure } from '@chakra-ui/react'
import * as Yup from 'yup';
import {InputText,Form,InputDateField,SelectField} from '../../components/form'
import { HStack, Box, Text } from '@chakra-ui/react';
import { toast } from "react-toastify";
import { fetchWrapper } from '../../helpers';




function Edit({rs,loadList,meCloseEdit}){

	
	const [formData, setFormData] = useState({
    name: rs.name,
    monthly_cost: rs.monthly_cost,
    yearly_discount: rs.yearly_discount,
    subscription_days: rs.subscription_days,
    grace_period: rs.grace_period,
    allow_story: rs.allow_story,
    status_id: rs.status_id,
    sandbox_url:rs.sandbox_url,
    prod_url:rs.prod_url,
    
    id:rs.id
    
    
  });

  const FormSchema = Yup.object().shape({
	   name: Yup.string().required('Required'),
	   monthly_cost: Yup.string().required('Required'),
	   
	   yearly_discount: Yup.string().required('Required'),
	   subscription_days: Yup.string().required('Required'),
	   grace_period: Yup.string().required('Required'),
	   allow_story: Yup.string().required('Required'),
	   status_id: Yup.string().required('Required'),
	   sandbox_url: Yup.string().required('Required'),
	   prod_url: Yup.string().required('Required'),

	});


	const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

		   let  data = values
		  
	       
	     const url = `${process.env.REACT_APP_API_URL}/plan/update`;
  		 await fetchWrapper.post(url,data)
  		 loadList()
  		 meCloseEdit();
  		 toast(`Record has been saved `, {
	        type: "info"
	      });
	}

	
	

	return (

			<>
			<Form
			            enableReinitialize
			            validationSchema={FormSchema}
			            initialValues={formData}
			            onSubmit={onSubmit}
			 >

			 	
			 		<Box w="80%">
			 			<InputText name="name" title="Plan Name"  />
			 		</Box>

			 		<HStack>
			 			<HStack width="81%">
					 		<Box w="100%">
					 			<InputText name="monthly_cost" title="Monthly Cost"  />
					 		</Box>

					 		<Box w="100%">
					 			<InputText name="yearly_discount" title="Yearly Discount"  />
					 		</Box>
					 	</HStack>

					 	<Box w="19%">
					 		<Text color="#000" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif" textAlign="center">$ Total Yearly Cost</Text>
					 	</Box>
			 		</HStack>

			 		<HStack width="80%">
				 		<Box w="100%">
				 			<InputText name="subscription_days" title="Subscription No of Days"  />
				 		</Box>


				 		<Box w="100%">
				 			<InputText name="grace_period" title="Grace Period"  />
				 		</Box>
			 		</HStack>

			 		<HStack width="80%">
				 		<Box w="100%">
				 			<InputText name="allow_story" title="No of Stories Allowed / Month"  />
				 		</Box>

				 		<Box w="100%">
				 			<SelectField list={{rows:[{name:"In Active",id:'0'},
                    {name:"Active",id:'1'}]}} name="status_id" title="Status"  />
				 		</Box>
				 	</HStack>


				 	<Box w="100%">
					 			<InputText name="sandbox_url" title="Product Price Id (Sandbox)"  />
					 		</Box>


					<Box w="100%">
					 			<InputText name="prod_url" title="Product Price Id (Production)"  />
					 		</Box>

		         <Button py="10px" px="25px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
				            mb={4}
				            
				            type='submit'
		          		>
		           		 Save
		          		</Button>

		          		<Button py="10px" px="25px" color="#504F56" fontSize="14px" fontWeight="500" fontFamily="'Lato', sans-serif"
		            mb={4}
		            colorScheme='whiteAlpha'
		            onClick={meCloseEdit}
		            type='button'
          		>
           		 Close
          		</Button>


			</Form>

			</>

	)
}

export default Edit;